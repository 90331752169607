import { ListProps } from "../../Components";
import { FiniteListWithAsyncLoaderAbstract } from "../../stories/FiniteListFilter/FiniteListWithAyncLoader.abstract";
import { useMercuryContext } from "../../user-context";
import { glms_stage } from "../../utils/variables";

export const fetchProjectRole = async (context: any) => {
   
    const response = await fetch(`${glms_stage}/glms/project/roles`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${context.accessToken}`,
        },
      });
      
    const data = await response.json();
    
    return data.map((item: any) => ({
      title: item.value,
      value: item.value,
    }));
  };
  
  class ProjectRoleFilter extends FiniteListWithAsyncLoaderAbstract {
    fetch() {
      return fetchProjectRole(this.props.context);
    }
  }
  
  export default (props: ListProps) => {
    const context = useMercuryContext();
    return <ProjectRoleFilter context={context} {...props} />;
  };